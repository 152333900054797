import * as globals from "./globals.js"
import moment from "./moment-localised.js";

/**
 * These date/time formats should be used for all front end <--> back end communication. It is a simple date format that
 * mirrors the MySQL datetime format. It has no timezone support and therefore works in the majority of cases where
 * the context for a datetime should be the farms timezone as understood by the server. There is a matching version
 * formatted for use within PHP at \App\Figured\Packages\Dates\Facades\Date::DATE_TIME.
 */
export const DATE_FORMAT      = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const MOMENT_DATE_FORMATS = {
    monthYear: {
        format: "YYYY-MM",
        displayFormat: "MMM Y",
    },

    monthYearShort: {
        displayFormat: "MMM YY",
    },

    monthNameYear: {
        format: "MMMM YYYY",
    },

    dayMonthTextYear: {
        format:        "YYYY-MM-DD",
        displayFormat: "DD MMM YYYY",
    },

    dateTime: {
        format: "YYYY-MM-DD HH:mm:ss",
    }
};

/**
 * Constants for Element UI date pickers should be added here
 */
export const ELEMENT_UI_DATE_FORMATS = {
    monthYear: {
        format:        "yyyy-MM",
        displayFormat: "MMM yyyy",
    },

    monthYearShort: {
        format:        "yyyy-MM",
        displayFormat: "MMM yy",
    },

    dayMonthTextYear: {
        format:        "yyyy-MM-dd",
        displayFormat: "dd MMM yyyy",
    },

    dayMonthYear: {
        format:        "yyyy-MM-dd",
        displayFormat: "dd/MM/yyyy",
    },
};

/**
 * Gets the year start date on a 12-month cycle
 *
 * @param {int} year
 * @param {int} yearEndMonth
 *
 * @returns {Moment}
 */
export function startOfYear(year, yearEndMonth) {
    let yearToUse = year;
    let yearEndMonthToUse = yearEndMonth;

    if (! _.isInteger(yearToUse)) {
        throw new TypeError(`Must have a valid year. Got: ${year}.`);
    }

    if (! _.isInteger(yearEndMonthToUse) || (yearEndMonthToUse > 12 || yearEndMonthToUse < 1)) {
        throw new TypeError(`Year-end month must be between 1 - 12. Got: ${yearEndMonthToUse}.`);
    }

    // If the year-end month is not December, the season start month is obviously in the previous year.
    // We need go back a year
    if (yearEndMonth !== 12) {
        yearToUse -= 1;
    }

    // If using the previous year, use the month just after the yearEnd month;
    // otherwise, it's obvious that January is our starting month
    let monthToUse = year > yearToUse ? yearEndMonthToUse + 1 : 1;

    return moment()
        .date(1)
        .month(monthToUse - 1) // Months are zero-based on moment.js, so subtract 1
        .year(yearToUse)
        .startOf("day");
}

/**
 * Gets the year end date on a 12-month cycle
 *
 * @param {int} year
 * @param {int} yearEndMonth
 *
 * @returns {Moment}
 */
export function endOfYear(year, yearEndMonth) {
    return startOfYear(year, yearEndMonth).add(11, "M").endOf("month");
}

/**
 * Gets the year to use based on the year-end month and day
 *
 * Similar to Farm::getFinancialYearFromDate()
 *
 * @param {moment.Moment} date
 * @param {int} yearEndMonth
 * @param {int} yearEndDay
 *
 * @returns {int} The year to use
 */
export function dateToYear(date, yearEndMonth, yearEndDay) {
    if (!date || ! date.isValid()) {
        throw new EvalError("Date passed is not valid.");
    }

    if (! _.isInteger(yearEndMonth)) {
        throw new TypeError(`Must have a valid year-end month. Got: ${yearEndMonth}.`);
    }

    if (! _.isInteger(yearEndDay)) {
        throw new TypeError(`Must have a valid year-end day. Got: ${yearEndDay}.`);
    }

    const monthToUse = date.month();
    const yearToUse = date.year();
    const dayToUse = date.date();

    // If the month exceeds the year-end month, use the following year
    if (monthToUse > yearEndMonth) {
        return yearToUse + 1;
    }

    // If the month is the same as the year-end month, but the day lapses, still use the following year
    if ((monthToUse === yearEndMonth) && (dayToUse > yearEndDay)) {
        return yearToUse + 1;
    }

    // Otherwise, use the same year by the date
    return yearToUse;
}


/**
 * Gets the year to use based on the financial year year-end
 *
 * @param {Moment} date
 */
export function dateToFinancialYear(date) {
    let yearEndMonth = parseInt(globals.get("financialYearEndMonth")) - 1;
    let yearEndDay   = parseInt(globals.get("financialYearEndDay"));

    return dateToYear(date, yearEndMonth, yearEndDay);
}

/**
 * Gets the year to use based on the season year-end month and day
 *
 * @param {Moment} date
 */
export function dateToSeasonYear(date) {
    let yearEndMonth = parseInt(globals.get("seasonYearEndMonth")) - 1;
    let yearEndDay   = parseInt(globals.get("seasonYearEndDay"));

    return dateToYear(date, yearEndMonth, yearEndDay);
}

/**
 *  Gets the season/budget year start date
 *
 * @param {int} year
 * @param {int|null} seasonYearEndMonth
 *
 * @returns {Moment}
 */
export function startOfSeasonYear(year, seasonYearEndMonth = null) {
    seasonYearEndMonth = _.defaultTo(seasonYearEndMonth, parseInt(globals.get("seasonYearEndMonth")));

    return startOfYear(year, seasonYearEndMonth);
}

/**
 * Gets the season/budget year end date
 *
 * @param {int} year
 *
 * @returns {Moment}
 */
export function endOfSeasonYear(year) {
    /**
     * Bug in moment .endOf() means we must retrieve the correct date and then convert back to moment object
     */
    let endOfSeasonYear = startOfSeasonYear(year).add(11, "M").endOf("month").format("YYYY-MM-DD");
    return moment(endOfSeasonYear);
}

/**
 * Receives an object that is keyed by a date, and return the value that is
 * at the first position on or after the given date. This method returns null
 * if all the dates in the object come after the given date.
 *
 * @param {Moment} date
 * @param {Object} obj
 */
export function seekToDate(obj, date) {

    // Get all date boundaries, starting with the most recent.
    let dates = _.keys(obj).sort().reverse();

    // As soon as we find a candidate that comes before the target, we know
    // that we're in between the two date boundaries around the target.
    if ((date = moment(date))) {
        for (let candidate of dates) {

            if (date.isSameOrAfter(candidate)) {
                return _.get(obj, candidate);
            }
        }
    }

    return null;
}

/**
 * Datepicker element `date` is the JavaScript Date object with current browser time zone (TZ)
 * that is not the same as moment-localised TZ. We need to get Datepicker element current year,
 * month and day (ignoring TZ difference between current TZ and moment-localised TZ) and
 * convert it into the moment object.
 *
 * @param {Object} date
 * @return {Moment}
 */
export function elementDatePickerToMoment(date) {
    const year  = date.getFullYear();
    const month = date.getMonth();
    const day   = date.getDate();

    return moment([year, month, day]);
}
